@use "../variables.scss";
@use "../mixins.scss";

.hero-home {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3.2rem 0;
  background-color: variables.$hero-background;
  .hero-home-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
    max-width: 1240px;
    color: variables.$color-title;
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    .hero-subtitle {
      font-size: 3.8rem;
      @media (max-width: 1200px) {
        font-size: 3.5rem;
      }
      @media (max-width: 1000px) {
        font-size: 3rem;
      }
    }
    .hero-copy {
      font-size: 1.2rem;
    }
    .contact-btn {
      @include mixins.contact-btn;
      border: none;
      font-size: 0.9rem;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .photo-container {
    display: flex;
    justify-content: flex-end;
    max-width: 1240px;
    @media (max-width: 875px) {
        display: none;
      }
    .photo-hero {
      position: absolute;
      height: 450px;
      margin: 0 3rem;
      @media (max-width: 1200px) {
        height: 425px;
      }
      @media (max-width: 1000px) {
        height: 400px;
        margin: 0 2rem;
      }
    }
  }
}
