@use "../mixins.scss";
@use "../variables.scss";

#admin-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0;
  margin-bottom: 3rem;
  background: rgb(63, 94, 251);
  background: linear-gradient(
    137deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  color: white;
}
.admin-header {
  max-width: 1240px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  position: relative;
  .admin-title {
    font-size: 3rem;
  }
  .logout-btn {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    position: absolute;
    top: 0;
    background-color: unset;
    border: none;
    font-size: 0.9rem;
    color: white;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.admin-msg {
  max-width: 1240px;
  width: 90vw;
  font-style: italic;
  p {
    color: white;
  }
}
