@use "../variables.scss";
@use "../mixins.scss";

.about-home-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //max-width: 1240px;
  width: 100%;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  margin: 0 auto;
  .firula-container {
    margin-bottom: 1.5rem;
    max-width: 1240px;
    width: 100%;
    align-self: center;
    @media (max-width: 875px) {
      display: none;
    }
  }
  .about-home-copy {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
    max-width: 1240px;
    margin: 0 auto;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    .copy-col-1 {
      font-size: 2rem;
      font-weight: 800;
      max-width: 90%;
      @media (max-width: 1024px) {
        margin: 0;
        text-align: center;
        align-self: center;
      }
      p {
        color: variables.$color-title;
        margin-top: 0;
      }
    }
    .copy-col-2 {
      color: variables.$gray-texte;
      font-size: 1.1rem;
      max-width: 90%;
      @media (max-width: 1024px) {
        margin: 0;
        text-align: center;
        align-self: center;
      }
      @media (max-width: 768px) {
        margin-top: 1rem;
      }
    }
  }
}
