@use "../mixins.scss";
@use "../variables.scss";

#mentoring-services {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
  .mentoring-title-container {
    max-width: 1240px;
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    width: 100%;
    margin: 0 auto;
    h2 {
      align-self: flex-start;
      width: 100%;
      color: variables.$color-title;
      font-size: 2.5rem;
      font-weight: 800;
    }
  }
  .services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
  }
}
