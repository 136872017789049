@use "../mixins.scss";
@use "../variables.scss";

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  gap: 0.5rem;
  background: rgb(32, 173, 150);
  background: linear-gradient(
    156deg,
    rgba(32, 173, 150, 1) 0%,
    rgba(51, 52, 97, 1) 72%
  );
  h1 {
    color: white;
  }
  input {
    padding: 0.5rem;
    border: none;
    border-radius: 0.2rem;
    margin: 0.5rem 0;
  }
  .error-msg {
    color: white;
  }
  .btn-container {
    display: flex;
    gap: 1rem;
    .login-btn,
    .back-btn {
      @include mixins.contact-btn;
    }
    .back-btn {
      background-color: variables.$gray-texte;
    }
  }
}
