@use "../variables.scss";
@use "../mixins.scss";

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: variables.$gray-texte;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  h2 {
    font-size: 600;
    margin-bottom: 2rem;
  }
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
    text-align: center;
    @media (max-width: 575px) {
      margin: 1rem;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 15px;
      font-size: 24px;
      cursor: pointer;
    }
    form div {
      margin-bottom: 15px;
      text-align: start;
      label {
        display: block;
        margin-bottom: 5px;
      }
      input,
      select,
      textarea {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        border: solid 0.5px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: variables.$gray-texte;
        option {
          color: variables.$gray-texte;
        }
      }
    }
    button {
      @include mixins.contact-btn;
      color: white;
      width: 50%;
      cursor: pointer;
    }
    .contact-options {
      margin-top: 20px;
      text-align: center;
      font-size: .9rem;
      .contact-icons a {
        display: block;
        margin: 5px 0;
        color: variables.$gray-texte;
        text-decoration: none;
      }
      .contact-icons i {
        margin-right: 8px;
      }
      .contact-icons a:hover {
        color: #000;
      }
    }
  }
}
