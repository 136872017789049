@use "../mixins.scss";
@use "../variables.scss";

.admin-btn-container {
  display: flex;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  button {
    background-color: unset;
    border: none;
    font-size: 1rem;
    color: variables.$gray-texte;
    opacity: .7;
    &.selected {
        color: variables.$green;
        opacity: 1;
        font-weight: 600;
        font-size: 1.3rem;
    }
  }
}
