@use "../mixins.scss";
@use "../variables.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  .popup-content {
    background-color: variables.$gray-background;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 90%;
    margin: 1rem;

    h4 {
      color: variables.$color-title;
      font-size: 1.8rem;
      font-weight: 800;
      margin-bottom: 1rem;
    }

    p {
      color: variables.$gray-texte;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    button {
      background-color: variables.$green;
      color: white;
      padding: 0.5rem 1rem;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;

      &:hover {
        background-color: darken(variables.$green, 10%);
      }
    }
  }
}
