@use "../mixins.scss";
@use "../variables.scss";

.services-section {

    box-sizing: border-box;
  
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 100%;
  max-width: 1240px;
  margin-top: 5rem;
  align-self: center;
  @media (max-width: 1024px) {
    margin-top: 1rem;
  }
  .services-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem;
    margin: 0 10%;
    justify-content: center;
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      margin: 0;
      padding: 0;
    }
    @media (max-width: 1024px) {
      gap: 5rem;
    }
    .service-copy {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.75rem;
      height: 100%;
      @media (max-width: 1024px) {
        align-items: center;
      }
      .service-icon {
        object-fit: contain;
        height: 3.75rem;
        @media (max-width: 1024px) {
          height: 4.5rem;
        }
      }
      .service-title {
        font-size: 1.2rem;
        color: variables.$color-title;
        @media (max-width: 875) {
          font-size: 1.2rem;
        }
      }
      p {
        margin: 0;
        color: variables.$gray-texte;
        margin-bottom: auto; /* Empurra o botão para baixo, mantendo-o mais próximo do texto */
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
      .more-info {
        @include mixins.contact-btn;
        font-size: 0.9rem;
        font-weight: 400;
        margin-top: 0.75rem; /* Ajusta o espaço acima do botão para manter uma proximidade adequada */
      }
    }
  }
}
