@use "./variables.scss";

@mixin logo {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  color: variables.$color-title;
  font-weight: 700;
  font-size: 1.2rem;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

@mixin contact-btn {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: variables.$green;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  &:hover {
    opacity: 0.9;
    transform: scale(1.02);
  }
}

@mixin responsive-padding($padding-large, $padding-medium, $padding-small) {
  padding-left: $padding-large;
  padding-right: $padding-large;

  @media (max-width: 1240px) {
    padding-left: $padding-medium;
    padding-right: $padding-medium;
  }

  @media (max-width: 768px) {
    padding-left: $padding-small;
    padding-right: $padding-small;
  }
}
