@use "../mixins.scss";
@use "../variables.scss";

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  background-color: variables.$gray-background;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  @media (max-width: 768px) {
    align-items: unset;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
    @media (max-width: 768px) {
      flex-direction: column;
      width: unset;
      gap: 2.25rem;
      align-items: center;
      text-align: center;
    }
    .footer-cols {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: variables.$gray-texte;
      .footer-link {
        text-decoration: none;
        font-size: 0.9rem;
        color: variables.$gray-texte;
      }
    }
    .logo {
      @include mixins.logo;
    }
  }
  hr {
    max-width: 1240px;
    width: 100%;
    margin: 2rem 0 1rem 0;
  }
  .signature {
    margin: 0 0 1rem 0;
    font-size: 0.8rem;
    color: variables.$gray-texte;
    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      gap: 0.2rem;
    }
    .icon-heart {
      color: #f25949;
    }
    .link-signature {
      text-decoration: none;
      color: variables.$gray-texte;
      font-weight: 600;
    }
  }
}
