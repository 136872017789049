@use "../mixins.scss";
@use "../variables.scss";

#ebooks-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  .ebooks-header {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    h2 {
      font-size: 1.2rem;
      color: variables.$green;
    }
    .filter-ebooks-container {
      display: flex;
      gap: 1rem;
      margin: 1.5rem 0;
      .ebooks-category {
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: 400;
        color: variables.$gray-texte;
        background-color: unset;
        border: none;
        border-radius: 5px;
        text-decoration: underline;
        &:hover {
          color: variables.$green;
        }
        &.selected {
          color: variables.$green;
          font-weight: 600;
          text-decoration: none;
        }
      }
    }
  }
  .ebooks-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
    max-width: 1240px;
    width: 100%;
    margin: 2rem auto;
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr); 
    }
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    .ebook-card {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: variables.$gray-background;
      border-radius: 10px;
      min-height: 350px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        transform: scale(1.02);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        object-position: top;
        border-radius: 10px 10px 0 0;
      }
      .ebook-description {
        flex-grow: 1;

        padding: 1rem;
        h3 {
          color: variables.$color-title;
          font-weight: 800;
          font-size: 1.3rem;
        }
        .btn-link {
          margin-top: auto;
          display: inline-block;
          padding: 8px 12px;
          background-color: variables.$green;
          color: #fff;
          border-radius: 5px;
          text-decoration: none;
          text-align: center;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
