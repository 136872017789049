@use "../mixins.scss";
@use "../variables.scss";

#courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  .courses-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}
