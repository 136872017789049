@use "../mixins.scss";
@use "../variables.scss";

#about-me-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  @media (max-width: 1000px) {
    grid-template-columns: 2fr 3fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
  .image-container {
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    padding-right: 0;
    @media (max-width: 768px) {
        padding-right: 1rem;

      }
      
    .photo-about-me {
      width: 100%;
      height: 425px;
      object-fit: cover;
      object-position: bottom;
      @media (max-width: 768px) {
        height: 375px;
        width: 100%;
        object-position: center;
      }
    }
  }
  .about-me-copy {
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    justify-content: flex-end;
    @media (max-width:768px) {
        align-items: center;
        text-align: center;
    }
    p {
      width: 90%;
      margin: 0;
    }
    p:first-of-type {
      font-size: 2rem;
      font-weight: 800;
      margin-bottom: 1rem;
      color: variables.$color-title;
    }
    a {
      text-decoration: none;
      color: variables.$green;
    }
}
}
