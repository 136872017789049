@use "../mixins.scss";
@use "../variables.scss";


#mentoring-services-adm {
    @include mixins.responsive-padding(3rem, 2rem, 1rem);
    .service-adm {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        margin-top: 1.5rem;
    }
}
