@use "../variables.scss";

.popup-container {
  position: fixed;
  bottom: 40px; 
  right: 20px; 
  z-index: 1000;
  max-width: 300px;
  &.popup-animated { // Classe para animação
    animation: shake 0.5s ease-in-out; // Adiciona a animação de tremor
  }
  @keyframes shake {
    0% { transform: translate(0); }
    25% { transform: translate(-4px, 0); }
    50% { transform: translate(4px, 0); }
    75% { transform: translate(-4px, 0); }
    100% { transform: translate(0); }
  }
  .popup-call-to-action {
    background-color: variables.$green; 
    color: white;
    font-size: .9rem;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
}
.popup-expanded-content {
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative; 
  .popup-header {
    display: flex;
    justify-content: flex-end; 
    margin-bottom: 10px;
  }
  h3 {
    font-size: 1.1rem;
    color: variables.$color-title;
  }
  .popup-link {
    color: #25d366;
    text-decoration: none;
    display: block;
    margin-top: 10px;
    text-align: center;
  }
  .popup-icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    font-size: 18px;
    padding: 0;
    margin-left: 0.8rem;
  }
}

  