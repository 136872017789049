$color-title: #333461;
$gray-texte: #4a556c;
$gray-background: #faf8f6;
$green: #20ad96;
$hero-background: #F5F5F5;

h1 {
  font-size: 3.8rem;
  font-weight: 400;
  margin: 0;
  @media (max-width: 1200px) {
    font-size: 3.5rem;
  }
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  font-size: 1.8rem;
  font-weight: 800;
  margin: 0;
}

h5 {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

p {
  color: $gray-texte;
}