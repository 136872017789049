@use "../variables.scss";

.service-card {
  border-radius: 0.5rem;
  padding: 1.5rem;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 1200px) {
    max-width: 450px;
  }
  @media (max-width: 875px) {
    max-width: 300px;
  }
  @media (max-width: 475px) {
    padding: 1rem;
    margin: 1rem;
  }
  h3 {
    font-size: 1.5rem;
    color: variables.$green;
    margin-bottom: 0.75rem;
  }
  a {
    align-self: center;
    text-decoration: none;
    color: variables.$green;
    font-weight: 500;
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    border: 1px solid variables.$green;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    &:hover,
    &:focus {
      background-color: variables.$green;
      color: white;
    }
  }
}
