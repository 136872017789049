@charset "UTF-8";
h1 {
  font-size: 3.8rem;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 1200px) {
  h1 {
    font-size: 3.5rem;
  }
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  font-size: 1.8rem;
  font-weight: 800;
  margin: 0;
}

h5 {
  font-size: 0.9rem;
  margin: 0.5rem 0;
}

p {
  color: #4a556c;
}

nav {
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
}
nav .hamburger {
  display: none;
}
@media (max-width: 992px) {
  nav .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: self-end;
    width: 100%;
    height: 2rem;
    padding: 0 1rem;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }
  nav .hamburger .burger {
    width: 2rem;
    height: 0.25rem;
    background-color: gray;
    border-radius: 10px;
    transition: all 0.3s linear;
  }
}
nav .navbar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  nav .navbar-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  nav .navbar-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
nav .navbar-container .logo-container {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  nav .navbar-container .logo-container {
    justify-content: center;
    padding: 2rem;
  }
}
nav .navbar-container .logo {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  color: #333461;
  font-weight: 700;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  nav .navbar-container .logo {
    font-size: 2rem;
  }
}
@media (max-width: 992px) {
  nav .navbar-container {
    flex-direction: column;
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    background-color: #faf8f6;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    max-height: 0;
  }
  nav .navbar-container.open {
    max-height: 90vh;
    z-index: 1000;
    padding-bottom: 4rem;
    background-color: #faf8f6;
  }
}
nav .navbar-container .links-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 992px) {
  nav .navbar-container .links-container {
    flex-direction: column;
    gap: 1rem;
  }
}
nav .navbar-container .links-container a {
  color: #4a556c;
  text-decoration: none;
}
nav .navbar-container .links-container a.active {
  color: #20ad96;
  font-weight: 600;
}
@media (max-width: 992px) {
  nav .navbar-container .links-container a {
    padding: 1rem 0;
    text-align: center;
  }
}
nav .navbar-container .links-container .contato-button {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}
nav .navbar-container .links-container .contato-button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  background-color: #faf8f6;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 768px) {
  footer {
    align-items: unset;
  }
}
footer .footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
}
@media (max-width: 768px) {
  footer .footer-content {
    flex-direction: column;
    width: unset;
    gap: 2.25rem;
    align-items: center;
    text-align: center;
  }
}
footer .footer-content .footer-cols {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #4a556c;
}
footer .footer-content .footer-cols .footer-link {
  text-decoration: none;
  font-size: 0.9rem;
  color: #4a556c;
}
footer .footer-content .logo {
  display: flex;
  gap: 1rem;
  align-items: center;
  text-decoration: none;
  color: #333461;
  font-weight: 700;
  font-size: 1.2rem;
}
@media (max-width: 768px) {
  footer .footer-content .logo {
    font-size: 2rem;
  }
}
footer hr {
  max-width: 1240px;
  width: 100%;
  margin: 2rem 0 1rem 0;
}
footer .signature {
  margin: 0 0 1rem 0;
  font-size: 0.8rem;
  color: #4a556c;
}
@media (max-width: 768px) {
  footer .signature {
    display: flex;
    justify-content: center;
    gap: 0.2rem;
  }
}
footer .signature .icon-heart {
  color: #f25949;
}
footer .signature .link-signature {
  text-decoration: none;
  color: #4a556c;
  font-weight: 600;
}

.free-content-section {
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  gap: 5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #faf8f6;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  .free-content-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .free-content-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.free-content-section .free-content-title-container {
  max-width: 1240px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
}
.free-content-section .free-content-title-container .free-content-title {
  color: #333461;
  font-size: 2rem;
  font-weight: 800;
}
.free-content-section .free-content-container {
  display: flex;
  max-width: 1204px;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 576px) {
  .free-content-section .free-content-container {
    flex-direction: column;
    gap: 1.75rem;
  }
}
.free-content-section .free-content-container a {
  text-decoration: none;
  text-align: center;
  color: #4a556c;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.free-content-section .free-content-container a .free-content-logo {
  height: 5.25rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.free-content-section .free-content-container a .free-content-logo:hover {
  transform: scale(1.05);
}
.free-content-section .free-content-container a p {
  margin: 0;
}

.hero-home {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3.2rem 0;
  background-color: #F5F5F5;
}
.hero-home .hero-home-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  width: 100%;
  max-width: 1240px;
  color: #333461;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  .hero-home .hero-home-content {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .hero-home .hero-home-content {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.hero-home .hero-home-content .hero-subtitle {
  font-size: 3.8rem;
}
@media (max-width: 1200px) {
  .hero-home .hero-home-content .hero-subtitle {
    font-size: 3.5rem;
  }
}
@media (max-width: 1000px) {
  .hero-home .hero-home-content .hero-subtitle {
    font-size: 3rem;
  }
}
.hero-home .hero-home-content .hero-copy {
  font-size: 1.2rem;
}
.hero-home .hero-home-content .contact-btn {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  border: none;
  font-size: 0.9rem;
  font-weight: 700;
  cursor: pointer;
}
.hero-home .hero-home-content .contact-btn:hover {
  opacity: 0.9;
  transform: scale(1.02);
}
.hero-home .photo-container {
  display: flex;
  justify-content: flex-end;
  max-width: 1240px;
}
@media (max-width: 875px) {
  .hero-home .photo-container {
    display: none;
  }
}
.hero-home .photo-container .photo-hero {
  position: absolute;
  height: 450px;
  margin: 0 3rem;
}
@media (max-width: 1200px) {
  .hero-home .photo-container .photo-hero {
    height: 425px;
  }
}
@media (max-width: 1000px) {
  .hero-home .photo-container .photo-hero {
    height: 400px;
    margin: 0 2rem;
  }
}

.about-home-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 0 auto;
}
@media (max-width: 1240px) {
  .about-home-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .about-home-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.about-home-container .firula-container {
  margin-bottom: 1.5rem;
  max-width: 1240px;
  width: 100%;
  align-self: center;
}
@media (max-width: 875px) {
  .about-home-container .firula-container {
    display: none;
  }
}
.about-home-container .about-home-copy {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  max-width: 1240px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .about-home-container .about-home-copy {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
.about-home-container .about-home-copy .copy-col-1 {
  font-size: 2rem;
  font-weight: 800;
  max-width: 90%;
}
@media (max-width: 1024px) {
  .about-home-container .about-home-copy .copy-col-1 {
    margin: 0;
    text-align: center;
    align-self: center;
  }
}
.about-home-container .about-home-copy .copy-col-1 p {
  color: #333461;
  margin-top: 0;
}
.about-home-container .about-home-copy .copy-col-2 {
  color: #4a556c;
  font-size: 1.1rem;
  max-width: 90%;
}
@media (max-width: 1024px) {
  .about-home-container .about-home-copy .copy-col-2 {
    margin: 0;
    text-align: center;
    align-self: center;
  }
}
@media (max-width: 768px) {
  .about-home-container .about-home-copy .copy-col-2 {
    margin-top: 1rem;
  }
}

.services-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  width: 100%;
  max-width: 1240px;
  margin-top: 5rem;
  align-self: center;
}
@media (max-width: 1024px) {
  .services-section {
    margin-top: 1rem;
  }
}
.services-section .services-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;
  margin: 0 10%;
  justify-content: center;
}
@media (max-width: 1024px) {
  .services-section .services-container {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .services-section .services-container {
    gap: 5rem;
  }
}
.services-section .services-container .service-copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.75rem;
  height: 100%;
}
@media (max-width: 1024px) {
  .services-section .services-container .service-copy {
    align-items: center;
  }
}
.services-section .services-container .service-copy .service-icon {
  object-fit: contain;
  height: 3.75rem;
}
@media (max-width: 1024px) {
  .services-section .services-container .service-copy .service-icon {
    height: 4.5rem;
  }
}
.services-section .services-container .service-copy .service-title {
  font-size: 1.2rem;
  color: #333461;
}
@media (max-width: 875) {
  .services-section .services-container .service-copy .service-title {
    font-size: 1.2rem;
  }
}
.services-section .services-container .service-copy p {
  margin: 0;
  color: #4a556c;
  margin-bottom: auto; /* Empurra o botão para baixo, mantendo-o mais próximo do texto */
}
@media (max-width: 1024px) {
  .services-section .services-container .service-copy p {
    text-align: center;
  }
}
.services-section .services-container .service-copy .more-info {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 0.75rem; /* Ajusta o espaço acima do botão para manter uma proximidade adequada */
}
.services-section .services-container .service-copy .more-info:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.hero-pages {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F5F5F5;
}
.hero-pages .hero-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1240px;
  padding: 3.2rem 0;
  padding-left: 3rem;
  padding-right: 3rem;
  gap: 0.5rem;
  color: #333461;
}
@media (max-width: 1240px) {
  .hero-pages .hero-content-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .hero-pages .hero-content-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.hero-pages .hero-content-container .hero-pages-title {
  font-size: 3.8rem;
  font-weight: 700;
  padding-top: 1.5rem;
}
@media (max-width: 1200px) {
  .hero-pages .hero-content-container .hero-pages-title {
    font-size: 3.5rem;
  }
}
@media (max-width: 1000px) {
  .hero-pages .hero-content-container .hero-pages-title {
    font-size: 3rem;
  }
}
.hero-pages .hero-content-container .hero-pages-description {
  font-size: 1.2rem;
  width: 75%;
  margin: 0;
}
@media (max-width: 576px) {
  .hero-pages .hero-content-container .hero-pages-description {
    width: 100%;
  }
}

.pages-copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  max-width: 1240px;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1240px) {
  .pages-copy {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .pages-copy {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.pages-copy .copy-question {
  font-size: 1.5rem;
  color: #333461;
  font-weight: 700;
  margin: 0;
  width: 90%;
}
.pages-copy .copy-description {
  margin: 0;
  font-size: 1.1rem;
  width: 75%;
  color: #4a556c;
}

#mentoring-services {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  width: 100%;
}
#mentoring-services .mentoring-title-container {
  max-width: 1240px;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1240px) {
  #mentoring-services .mentoring-title-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #mentoring-services .mentoring-title-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#mentoring-services .mentoring-title-container h2 {
  align-self: flex-start;
  width: 100%;
  color: #333461;
  font-size: 2.5rem;
  font-weight: 800;
}
#mentoring-services .services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.service-card {
  border-radius: 0.5rem;
  padding: 1.5rem;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.service-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1200px) {
  .service-card {
    max-width: 450px;
  }
}
@media (max-width: 875px) {
  .service-card {
    max-width: 300px;
  }
}
@media (max-width: 475px) {
  .service-card {
    padding: 1rem;
    margin: 1rem;
  }
}
.service-card h3 {
  font-size: 1.5rem;
  color: #20ad96;
  margin-bottom: 0.75rem;
}
.service-card a {
  align-self: center;
  text-decoration: none;
  color: #20ad96;
  font-weight: 500;
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  border: 1px solid #20ad96;
  border-radius: 0.5rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.service-card a:hover, .service-card a:focus {
  background-color: #20ad96;
  color: white;
}

#consulting-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #consulting-services {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #consulting-services {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#consulting-services .services-title-container {
  width: 100%;
  box-sizing: border-box;
}
#consulting-services .services-title-container h2 {
  align-self: flex-start;
  color: #333461;
  font-size: 2.5rem;
  font-weight: 800;
}
#consulting-services .consulting-cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  justify-content: center;
  padding: 1rem;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  #consulting-services .consulting-cards-container {
    grid-template-columns: 1fr;
  }
}
#consulting-services .consulting-card {
  padding: 1.5rem;
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
#consulting-services .consulting-card:hover {
  transform: scale(1.02);
}
@media (max-width: 600px) {
  #consulting-services .consulting-card {
    padding: 1rem 0;
    margin: 0.5rem 0;
  }
}
#consulting-services .consulting-card .service-image {
  width: 25px;
  height: 25px;
  padding: 1rem;
  background-color: #F5F5F5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#consulting-services .consulting-card .service-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#consulting-services .consulting-card h3 {
  font-size: 1.5rem;
  color: #333461;
}
#consulting-services .consulting-card p {
  margin: 0;
}

#emotional-consulting {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #emotional-consulting {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #emotional-consulting {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#emotional-consulting h2 {
  align-self: flex-start;
  width: 90%;
  color: #333461;
  font-size: 2.5rem;
  font-weight: 800;
}
#emotional-consulting p {
  max-width: 1240px;
  width: 90vw;
  font-size: large;
}
#emotional-consulting ol {
  padding-left: 1rem;
  color: #4a556c;
}
#emotional-consulting ol li {
  margin-bottom: 0.5rem;
  position: relative;
  max-width: 90vw;
}
#emotional-consulting ol li::marker {
  content: counter(list-item) ") ";
  counter-increment: list-item;
  color: #20ad96;
  font-weight: 600;
}

#how-it-works {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #how-it-works {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #how-it-works {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#how-it-works h4 {
  max-width: 1240px;
  width: 100%;
  color: #20ad96;
  font-size: 1.4rem;
}
#how-it-works p {
  margin: 0;
  width: 90%;
}

#cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  width: 40%;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1240px) {
  #cta {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #cta {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 768px) {
  #cta {
    width: 80%;
  }
}
#cta p {
  font-size: 1.8rem;
  font-weight: 800;
  margin: 0;
}
#cta p span {
  color: #20ad96;
}
#cta button {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  border: none;
  font-size: 0.9rem;
  font-weight: 400;
  cursor: pointer;
}
#cta button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup .popup-content {
  background-color: #faf8f6;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  margin: 1rem;
}
.popup .popup-content h4 {
  color: #333461;
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 1rem;
}
.popup .popup-content p {
  color: #4a556c;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}
.popup .popup-content button {
  background-color: #20ad96;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}
.popup .popup-content button:hover {
  background-color: #188271;
}

#courses {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1240px;
  margin: 0 auto;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #courses {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #courses {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#courses .courses-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  #courses .courses-container {
    flex-direction: column;
  }
}

#about-me-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
}
@media (max-width: 1000px) {
  #about-me-container {
    grid-template-columns: 2fr 3fr;
  }
}
@media (max-width: 768px) {
  #about-me-container {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2rem;
  }
}
#about-me-container .image-container {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-right: 0;
}
@media (max-width: 1240px) {
  #about-me-container .image-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #about-me-container .image-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 768px) {
  #about-me-container .image-container {
    padding-right: 1rem;
  }
}
#about-me-container .image-container .photo-about-me {
  width: 100%;
  height: 425px;
  object-fit: cover;
  object-position: bottom;
}
@media (max-width: 768px) {
  #about-me-container .image-container .photo-about-me {
    height: 375px;
    width: 100%;
    object-position: center;
  }
}
#about-me-container .about-me-copy {
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: flex-end;
}
@media (max-width: 1240px) {
  #about-me-container .about-me-copy {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #about-me-container .about-me-copy {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 768px) {
  #about-me-container .about-me-copy {
    align-items: center;
    text-align: center;
  }
}
#about-me-container .about-me-copy p {
  width: 90%;
  margin: 0;
}
#about-me-container .about-me-copy p:first-of-type {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 1rem;
  color: #333461;
}
#about-me-container .about-me-copy a {
  text-decoration: none;
  color: #20ad96;
}

#mission {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 1240px) {
  #mission {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #mission {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1240px) {
  #mission {
    width: 50rem;
  }
}
#mission #accordion-mission,
#mission #accordion-values {
  border: none;
  box-shadow: none;
}
#mission #accordion-mission.Mui-expanded ~ #accordion-mission::before, #mission #accordion-mission.Mui-expanded ~ #accordion-values::before, #mission #accordion-mission.Mui-expanded::before,
#mission #accordion-values.Mui-expanded ~ #accordion-mission::before,
#mission #accordion-values.Mui-expanded ~ #accordion-values::before,
#mission #accordion-values.Mui-expanded::before {
  display: none;
}
#mission #accordion-mission.Mui-expanded,
#mission #accordion-values.Mui-expanded {
  background-color: #faf8f6;
}
#mission #accordion-mission .accordion-btn,
#mission #accordion-values .accordion-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #20ad96;
  border: solid 0.125rem #20ad96;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  padding: 0.25rem;
}
#mission #accordion-mission .accordion-title,
#mission #accordion-values .accordion-title {
  font-family: "Kumbh sans", sans-serif;
  font-size: 1.2rem;
  color: #333461;
  font-weight: 600;
}
#mission #accordion-mission .description,
#mission #accordion-values .description {
  font-family: "Kumbh sans", sans-serif;
}

#ebooks-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #ebooks-section {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #ebooks-section {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#ebooks-section .ebooks-header {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}
#ebooks-section .ebooks-header h2 {
  font-size: 1.2rem;
  color: #20ad96;
}
#ebooks-section .ebooks-header .filter-ebooks-container {
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
}
#ebooks-section .ebooks-header .filter-ebooks-container .ebooks-category {
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  color: #4a556c;
  background-color: unset;
  border: none;
  border-radius: 5px;
  text-decoration: underline;
}
#ebooks-section .ebooks-header .filter-ebooks-container .ebooks-category:hover {
  color: #20ad96;
}
#ebooks-section .ebooks-header .filter-ebooks-container .ebooks-category.selected {
  color: #20ad96;
  font-weight: 600;
  text-decoration: none;
}
#ebooks-section .ebooks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1240px;
  width: 100%;
  margin: 2rem auto;
}
@media (max-width: 1024px) {
  #ebooks-section .ebooks-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  #ebooks-section .ebooks-container {
    display: flex;
    flex-direction: column;
  }
}
#ebooks-section .ebooks-container .ebook-card {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #faf8f6;
  border-radius: 10px;
  min-height: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
#ebooks-section .ebooks-container .ebook-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
#ebooks-section .ebooks-container .ebook-card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px 10px 0 0;
}
#ebooks-section .ebooks-container .ebook-card .ebook-description {
  flex-grow: 1;
  padding: 1rem;
}
#ebooks-section .ebooks-container .ebook-card .ebook-description h3 {
  color: #333461;
  font-weight: 800;
  font-size: 1.3rem;
}
#ebooks-section .ebooks-container .ebook-card .ebook-description .btn-link {
  margin-top: auto;
  display: inline-block;
  padding: 8px 12px;
  background-color: #20ad96;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
}
#ebooks-section .ebooks-container .ebook-card .ebook-description .btn-link:hover {
  opacity: 0.9;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #4a556c;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.modal h2 {
  font-size: 600;
  margin-bottom: 2rem;
}
.modal .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
  text-align: center;
}
@media (max-width: 575px) {
  .modal .modal-content {
    margin: 1rem;
  }
}
.modal .modal-content .close {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}
.modal .modal-content form div {
  margin-bottom: 15px;
  text-align: start;
}
.modal .modal-content form div label {
  display: block;
  margin-bottom: 5px;
}
.modal .modal-content form div input,
.modal .modal-content form div select,
.modal .modal-content form div textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: solid 0.5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #4a556c;
}
.modal .modal-content form div input option,
.modal .modal-content form div select option,
.modal .modal-content form div textarea option {
  color: #4a556c;
}
.modal .modal-content button {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  color: white;
  width: 50%;
  cursor: pointer;
}
.modal .modal-content button:hover {
  opacity: 0.9;
  transform: scale(1.02);
}
.modal .modal-content .contact-options {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
}
.modal .modal-content .contact-options .contact-icons a {
  display: block;
  margin: 5px 0;
  color: #4a556c;
  text-decoration: none;
}
.modal .modal-content .contact-options .contact-icons i {
  margin-right: 8px;
}
.modal .modal-content .contact-options .contact-icons a:hover {
  color: #000;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
  gap: 0.5rem;
  background: rgb(32, 173, 150);
  background: linear-gradient(156deg, rgb(32, 173, 150) 0%, rgb(51, 52, 97) 72%);
}
.login-form-container h1 {
  color: white;
}
.login-form-container input {
  padding: 0.5rem;
  border: none;
  border-radius: 0.2rem;
  margin: 0.5rem 0;
}
.login-form-container .error-msg {
  color: white;
}
.login-form-container .btn-container {
  display: flex;
  gap: 1rem;
}
.login-form-container .btn-container .login-btn,
.login-form-container .btn-container .back-btn {
  padding: 10px 20px;
  margin: 10px 0;
  background-color: #20ad96;
  color: white !important;
  text-align: center;
  border-radius: 5px;
  border: none;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}
.login-form-container .btn-container .login-btn:hover,
.login-form-container .btn-container .back-btn:hover {
  opacity: 0.9;
  transform: scale(1.02);
}
.login-form-container .btn-container .back-btn {
  background-color: #4a556c;
}

#admin-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem 0;
  margin-bottom: 3rem;
  background: rgb(63, 94, 251);
  background: linear-gradient(137deg, rgb(63, 94, 251) 0%, rgb(252, 70, 107) 100%);
  color: white;
}

.admin-header {
  max-width: 1240px;
  width: 90vw;
  display: flex;
  flex-direction: column;
  position: relative;
}
.admin-header .admin-title {
  font-size: 3rem;
}
.admin-header .logout-btn {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: absolute;
  top: 0;
  background-color: unset;
  border: none;
  font-size: 0.9rem;
  color: white;
  cursor: pointer;
}
.admin-header .logout-btn:hover {
  text-decoration: underline;
}

.admin-msg {
  max-width: 1240px;
  width: 90vw;
  font-style: italic;
}
.admin-msg p {
  color: white;
}

#admin-messages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#admin-messages-container h2 {
  margin-bottom: 20px;
}
#admin-messages-container .boite-mail {
  width: 90vw;
  max-width: 1240px;
}
#admin-messages-container .mail-container {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  width: 100%;
  background-color: #fff;
}
#admin-messages-container .mail-container.respondido {
  background-color: #f0f0f0; /* Cor de fundo escurecida para mensagens respondidas */
}
#admin-messages-container .mail-container .sender {
  font-weight: bold;
}
#admin-messages-container .mail-container .object {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
#admin-messages-container .mail-container .object button {
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 10px;
}
#admin-messages-container .mail-container .object button.respondido {
  background-color: #ccc;
}
#admin-messages-container .mail-container .object button.nao-respondido {
  background-color: #28a745;
}
#admin-messages-container .mail-container .object button.delete {
  background-color: #dc3545;
}
#admin-messages-container .mail-container .mensagem {
  margin-top: 10px;
  white-space: pre-wrap;
}

.admin-btn-container {
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  .admin-btn-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  .admin-btn-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.admin-btn-container button {
  background-color: unset;
  border: none;
  font-size: 1rem;
  color: #4a556c;
  opacity: 0.7;
}
.admin-btn-container button.selected {
  color: #20ad96;
  opacity: 1;
  font-weight: 600;
  font-size: 1.3rem;
}

#mentoring-services-adm {
  padding-left: 3rem;
  padding-right: 3rem;
}
@media (max-width: 1240px) {
  #mentoring-services-adm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media (max-width: 768px) {
  #mentoring-services-adm {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
#mentoring-services-adm .service-adm {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.popup-container {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
  max-width: 300px;
}
.popup-container.popup-animated {
  animation: shake 0.5s ease-in-out;
}
@keyframes shake {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-4px, 0);
  }
  50% {
    transform: translate(4px, 0);
  }
  75% {
    transform: translate(-4px, 0);
  }
  100% {
    transform: translate(0);
  }
}
.popup-container .popup-call-to-action {
  background-color: #20ad96;
  color: white;
  font-size: 0.9rem;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.popup-expanded-content {
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}
.popup-expanded-content .popup-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.popup-expanded-content h3 {
  font-size: 1.1rem;
  color: #333461;
}
.popup-expanded-content .popup-link {
  color: #25d366;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  text-align: center;
}
.popup-expanded-content .popup-icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #000;
  font-size: 18px;
  padding: 0;
  margin-left: 0.8rem;
}

html,
body {
  font-family: "Kumbh sans", sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-weight: 400;
  font-size: 1rem;
}

main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.content {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
}
@media (max-width: 1024px) {
  .content {
    gap: 4rem;
  }
}
.content > *:nth-child(1) {
  margin-bottom: -1.2rem;
}

.content.loaded {
  opacity: 1;
}

