@use "../mixins.scss";
@use "../variables.scss";

#emotional-consulting {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  h2 {
    align-self: flex-start;
    width: 90%;
    color: variables.$color-title;
    font-size: 2.5rem;
    font-weight: 800;
  }
  p {
    max-width: 1240px;
    width: 90vw;
    font-size: large;
  }
  ol {
    padding-left: 1rem;
    color: variables.$gray-texte;
    li {
      margin-bottom: 0.5rem;
      position: relative;
      max-width: 90vw;
      &::marker {
        content: counter(list-item) ") ";
        counter-increment: list-item;
        color: variables.$green;
        font-weight: 600;
      }
    }
  }
}
