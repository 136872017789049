@use "../mixins.scss";
@use "../variables.scss";

#consulting-services {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 1240px;
  margin: 0 auto;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  .services-title-container {
    width: 100%;
    box-sizing: border-box;
    h2 {
      align-self: flex-start;
      color: variables.$color-title;
      font-size: 2.5rem;
      font-weight: 800;
    }
  }
  .consulting-cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    justify-content: center;
    padding: 1rem;
    margin: 0 auto;
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }
  .consulting-card {
    padding: 1.5rem;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        transform: scale(1.02);
      }
    @media (max-width: 600px) {
      padding: 1rem 0;
      margin: 0.5rem 0;
    }
    .service-image {
      width: 25px;
      height: 25px;
      padding: 1rem;
      background-color: variables.$hero-background;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h3 {
      font-size: 1.5rem;
      color: variables.$color-title;
    }
    p {
      margin: 0;
    }
  }
}
