@use "../variables.scss";
@use "../mixins.scss";

#how-it-works {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  max-width: 1240px;

  margin: 0 auto;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  h4 {
    max-width: 1240px;
    width: 100%;
    color: variables.$green;
    font-size: 1.4rem;
  }
  p {
    margin: 0;
    width: 90%;
  }
}
