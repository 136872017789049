@use '../variables.scss';
@use '../mixins.scss';

.pages-copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
    max-width: 1240px;
    @include mixins.responsive-padding(0, 2rem, 1rem);
    .copy-question {
        font-size: 1.5rem;
        color: variables.$color-title;
        font-weight: 700;
        margin: 0;
        width: 90%;
    }
    .copy-description {
        margin: 0;
        font-size: 1.1rem;
        width: 75%;
        color: variables.$gray-texte;
    }
}