@use "../mixins.scss";
@use "../variables.scss";

#mission {
  display: flex;
  flex-direction: column;
  @include mixins.responsive-padding(0, 2rem, 1rem);
  @media (min-width: 1240px) {
    width: 50rem;
  }
  #accordion-mission,
  #accordion-values {
    border: none;
    box-shadow: none;
    &.Mui-expanded ~ #accordion-mission::before,
    &.Mui-expanded ~ #accordion-values::before,
    &.Mui-expanded::before {
      display: none;
    }
    &.Mui-expanded {
      background-color: variables.$gray-background;
    }
    .accordion-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: variables.$green;
      border: solid 0.125rem variables.$green;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0.25rem;
    }
    .accordion-title {
      font-family: "Kumbh sans", sans-serif;
      font-size: 1.2rem;
      color: variables.$color-title;
      font-weight: 600;
    }
    .description {
      font-family: "Kumbh sans", sans-serif;
    }
  }
}
