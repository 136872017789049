#admin-messages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin-bottom: 20px;
  }
  .boite-mail {
    width: 90vw;
    max-width: 1240px;
  }
  .mail-container {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background-color: #fff;
    &.respondido {
      background-color: #f0f0f0; /* Cor de fundo escurecida para mensagens respondidas */
    }
    .sender {
      font-weight: bold;
    }
    .object {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      button {
        padding: 5px 10px;
        color: #fff;
        border: none;
        border-radius: 3px;
        cursor: pointer;
        margin-left: 10px;
        &.respondido {
          background-color: #ccc;
        }
        &.nao-respondido {
          background-color: #28a745;
        }
        &.delete {
          background-color: #dc3545;
        }
      }
    }
    .mensagem {
      margin-top: 10px;
      white-space: pre-wrap;
    }
  }
}
