@use "../variables.scss";
@use "../mixins.scss";

#cta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  width: 40%;
  @include mixins.responsive-padding(0, 2rem, 1rem);
  @media (max-width: 768px) {
    width: 80%;
  }
  p {
    font-size: 1.8rem;
    font-weight: 800;
    margin: 0;
    span {
      color: variables.$green;
    }
  }
  button {
    @include mixins.contact-btn;
    border: none;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
  }
}
