@use "../variables.scss";
@use "../mixins.scss";

.free-content-section {
  display: flex;
  flex-direction: column;
  padding: 5rem 0;
  gap: 5rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: variables.$gray-background;
  @include mixins.responsive-padding(3rem, 2rem, 1rem);
  .free-content-title-container {
    max-width: 1240px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    .free-content-title {
      color: variables.$color-title;
      font-size: 2rem;
      font-weight: 800;    
    }
  }
  .free-content-container {
    display: flex;
    max-width: 1204px;
    justify-content: space-evenly;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 576px) {
      flex-direction: column;
      gap: 1.75rem;
    }
    a {
      text-decoration: none;
      text-align: center;
      color: variables.$gray-texte;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      align-items: center;
      .free-content-logo {
        height: 5.25rem;
        border-radius: 5px;
        margin-bottom: 0.5rem;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
          transform: scale(1.05);
        }
      }
      p {
        margin: 0;
      }
    }
  }
}
