@use "../variables.scss";
@use "../mixins.scss";

nav {
  display: flex;
  height: 5rem;
  justify-content: center;
  align-items: center;
  .hamburger {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: self-end;
      width: 100%;
      height: 2rem;
      padding: 0 1rem;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      .burger {
        width: 2rem;
        height: 0.25rem;
        background-color: gray;
        border-radius: 10px;
        transition: all 0.3s linear;
      }
    }
  }
  .navbar-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1240px;
    @include mixins.responsive-padding(3rem, 2rem, 1rem);

    .logo-container {
      display: flex;
      align-items: center;
      @media (max-width: 992px) {
        justify-content: center;
        padding: 2rem;
      }
    }
    .logo {
      @include mixins.logo;
    }
    @media (max-width: 992px) {
      flex-direction: column;
      position: absolute;
      top: 4rem;
      left: 0;
      width: 100%;
      background-color: variables.$gray-background;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;      
      max-height: 0;
      &.open {
        max-height: 90vh;
        z-index: 1000;
        padding-bottom: 4rem;
        background-color: variables.$gray-background;
      }
    }
    .links-container {
      display: flex;
      align-items: center;
      gap: 2rem;
      @media (max-width: 992px) {
        flex-direction: column;
        gap: 1rem;
      }
      a {
        color: variables.$gray-texte;
        text-decoration: none;
        &.active {
          color: variables.$green;
          font-weight: 600;
        }
        @media (max-width: 992px) {
          padding: 1rem 0;
          text-align: center;
        }
      }
      .contato-button {
        @include mixins.contact-btn;
      }
    }
  }
}
